import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { t } from "i18next";

function TableColumns({
  setUserId,
  setIsEditUserModalOpen,
  handleDeleteClick,
}) {
  const renderTitle = (className, id, value) => {
    return <span id={id}>{value}</span>;
  };

  const renderText = (value) => {
    return (
      <div>
        <span className="text-white text-opacity-80 font-light">{value}</span>
      </div>
    );
  };

  const tableColumns = [
    {
      title: renderTitle(null, "fullname-col", t("mobile_users.full_name")),
      key: "user_fullname",
      render: (object) => {
        const fullName = `${object?.FirstName} ${object?.LastName}`;
        return renderText(fullName);
      },
      width: 250,
      align: "center",
    },
    {
      title: renderTitle(null, null, t("mobile_users.mobile")),
      key: "user_phone",
      render: (object) => {
        return renderText(object?.PhoneNumber);
      },
      width: 250,
      align: "center",
    },
    {
      title: renderTitle(null, null, t("mobile_users.email")),
      key: "user_email",
      render: (object) => {
        return renderText(object?.Email);
      },
      width: 250,
      align: "center",
    },
    {
      title: renderTitle(null, null, t("mobile_users.property")),
      key: "user_property",
      render: (object) => {
        const uniqueNames = [
          ...new Set(object?.HomeAppUserZones?.map((x) => x.PropertyName)),
        ];
        const propertyNames = uniqueNames.join(", ");
        const text = renderText(propertyNames);
        return (
          <Tooltip
            styles={{
              root: {
                pointerEvents: "none",
              },
            }}
            color="#5a5a5a"
            title={text}
          >
            <span className="cursor-pointer">{text}</span>
          </Tooltip>
        );
      },
      width: 250,
      align: "center",
      ellipsis: true,
    },
    {
      title: renderTitle(null, null, t("mobile_users.zone")),
      key: "user_zone",
      render: (object) => {
        const uniqueNames = [
          ...new Set(object?.HomeAppUserZones?.map((x) => x.ZoneName)),
        ];
        const zoneNames = uniqueNames.join(", ");
        const text = renderText(zoneNames);
        return (
          <Tooltip
            styles={{
              root: {
                pointerEvents: "none",
              },
            }}
            color="#5a5a5a"
            title={text}
          >
            <span className="cursor-pointer">{text}</span>
          </Tooltip>
        );
      },
      width: 200,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: renderTitle(null, null, t("mobile_users.edit")),
      key: "user_edit",
      render: (object) => {
        return (
          <Button
            className="mr-1"
            key={`edit-${object.UserId}`}
            size="small"
            onClick={() => {
              setIsEditUserModalOpen(true);
              setUserId(object.UserId);
            }}
          >
            <EditOutlined />
          </Button>
        );
      },
      width: 100,
      align: "center",
    },
    {
      title: renderTitle(null, null, t("mobile_users.delete")),
      key: "user_delete",
      render: (object) => {
        return (
          <Button
            danger
            key={`delete-${object.UserId}`}
            className="ml-1"
            size="small"
            onClick={() => {
              handleDeleteClick(object);
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
      width: 100,
      align: "center",
    },
  ];

  return tableColumns;
}

export default TableColumns;
