import { useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

function PropertyFloorInput({ name, floorField, onRemove }) {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDeleteClick = (e) => {
    const floor = e.currentTarget.dataset;
    Modal.confirm({
      title: (
        <div className="text-center uppercase flex flex-col justify-center items-center">
          <ExclamationCircleOutlined
            style={{ fontSize: 36, color: "#ed1c24", marginBottom: "1rem" }}
          />
          {t("users.are_you_sure")}
        </div>
      ),
      content: (
        <div className="text-center uppercase">
          <p className="my-1">
            {t("users.delete_floor")} &quot;{floor.floorname}&quot;?
          </p>
          <p className="my-1">{t("users.undone")}</p>
        </div>
      ),
      className: "alert-delete-confirm",
      icon: null,
      onOk() {
        onRemove(name);
      },
      onCancel() {
        // Do Nothing
      },
      okButtonProps: {
        danger: true,
        className: "btn-leave",
      },
      okText: t("users.delete"),
      cancelText: t("mobile_users.cancel"),
      cancelButtonProps: { className: "btn-cancel" },
    });
  };

  const handleOnBlur = () => {
    setIsEditMode(false);
  };

  return (
    <Form.Item
      key={name}
      name={[name, "Name"]}
      rules={[
        {
          required: true,
          message: t("users.floor_required"),
        },
      ]}
    >
      <Input
        size="middle"
        disabled={!isEditMode}
        onBlur={handleOnBlur}
        addonAfter={
          <div className="">
            <Button
              type="primary"
              shape="default"
              size="small"
              icon={<EditOutlined />}
              onClick={handleEditClick}
              className="mr-1"
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={(e) => handleDeleteClick(e)}
              size="small"
              type="default"
              shape="default"
              className="text-triple-red ml-1"
              data-floorname={floorField.Name}
              data-floorid={floorField.Id}
            />
          </div>
        }
      />
    </Form.Item>
  );
}
PropertyFloorInput.defaultProps = {
  name: null,
  floorField: {},
  onRemove: () => {},
};

PropertyFloorInput.propTypes = {
  name: PropTypes.number,
  floorField: PropTypes.object,
  onRemove: PropTypes.func,
};
export default PropertyFloorInput;
