import { useEffect, useRef, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import {
  useHomeAppUser,
  useHomeAppUserZoneFilters,
  useUpdateHomeAppUser,
} from "../../../../api/hooks/useMobileAppUsers";
import FormUserZones from "./helpers/FormUserZones";
import mapper from "./helpers/mapper";
import { FormUserDetails, FormZoneSelection } from "./helpers/module";
import { useMobileAppUserUpdate, useHandleFieldsChange } from "./hooks/module";
import "./scss/MobileAppUserModal.scss";

function MobileAppUserEdit({ setIsFormDirty, setFormReference, userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [addZoneDisabled, setAddZoneDisabled] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [zones, setZones] = useState([]);
  const [editUserForm] = Form.useForm();
  const initialFormValues = useRef({});

  const editMobileAppUserMutationFn = useUpdateHomeAppUser();
  const { data: homeAppUserZoneFilters } = useHomeAppUserZoneFilters();
  const { refetch: getHomeAppUser } = useHomeAppUser(userId, {
    enabled: false,
  });

  useEffect(() => {
    getHomeAppUser(userId).then((response) => {
      const dataValue = response?.data?.Value;
      initialFormValues.current = mapper(initialFormValues.current, dataValue);
      editUserForm.setFieldsValue(initialFormValues.current);
    });
  }, [userId, getHomeAppUser, editUserForm]);

  useEffect(() => {
    setFormReference(editUserForm);
  }, [editUserForm, setFormReference]);

  const HandleSubmit = async (formValues) => {
    setIsLoading(true);
    const response = await useMobileAppUserUpdate(
      userId,
      formValues,
      setIsLoading,
      setIsFormDirty,
      editMobileAppUserMutationFn,
    );
    const dataValue = response?.data?.Value;
    initialFormValues.current = mapper(initialFormValues.current, dataValue);
    editUserForm.setFieldsValue(initialFormValues.current);
    setDisableSaveChanges(true);
  };

  const HandleFieldsChange = (changedFields) => {
    useHandleFieldsChange(
      changedFields,
      editUserForm,
      initialFormValues,
      setDisableSaveChanges,
      setIsFormDirty,
      setAddZoneDisabled,
    );
  };

  const handleOnValuesChange = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  const handlePropertyChange = (value) => {
    setSelectedProperty(value);
    const selectedProp = homeAppUserZoneFilters?.Value?.Properties.find(
      (prop) => prop.Id === value,
    );
    const alreadySelected = [
      ...editUserForm.getFieldValue("addUserZones"),
      ...editUserForm.getFieldValue("userZones"),
    ];

    if (selectedProp && alreadySelected?.length > 0) {
      const ids = alreadySelected.map((x) => x.zoneId);
      const zonesToSet = selectedProp.Zones.filter(
        (zone) => !ids.includes(zone.Id),
      );
      setZones(zonesToSet);
    } else {
      setZones(selectedProp ? selectedProp.Zones : []);
    }

    if (!selectedProp) {
      editUserForm.setFieldValue("property", null);
      editUserForm.setFieldValue("zone", null);
    }
  };

  return (
    <div className="content-wrapper overflow-y-auto edit-mobile-app-user-form mr-3">
      <Form
        form={editUserForm}
        name="edit-user-form"
        layout="horizontal"
        className="w-full"
        onFinish={HandleSubmit}
        onFieldsChange={HandleFieldsChange}
        onValuesChange={handleOnValuesChange}
        initialValues={{
          ...initialFormValues?.current,
        }}
      >
        <div className="user-title">
          <span className="text-center mr-1 tracking-wider">
            {t("mobile_users.edit_mobile_user")}
          </span>
          <EditOutlined className="text-lg" />
        </div>

        <FormUserDetails operation="edit" />
        <FormZoneSelection
          homeAppUserZoneFilters={homeAppUserZoneFilters}
          userForm={editUserForm}
          handlePropertyChange={handlePropertyChange}
          selectedProperty={selectedProperty}
          zones={zones}
        />
        <FormUserZones
          userForm={editUserForm}
          homeAppUserZoneFilters={homeAppUserZoneFilters}
          setAddZoneDisabled={setAddZoneDisabled}
          addZoneDisabled={addZoneDisabled}
          setZones={setZones}
          handlePropertyChange={handlePropertyChange}
          selectedProperty={selectedProperty}
        />
        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-create-user-save-btn"
          disabled={disableSaveChanges}
          loading={isLoading}
        >
          {t("mobile_users.save")}
        </Button>
      </Form>
    </div>
  );
}
MobileAppUserEdit.propTypes = {
  setIsFormDirty: PropTypes.func,
  setFormReference: PropTypes.func,
  userId: PropTypes.number.isRequired,
};
MobileAppUserEdit.defaultProps = {
  setIsFormDirty: () => {},
  setFormReference: () => {},
};
export default MobileAppUserEdit;
