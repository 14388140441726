import { InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { t } from "i18next";

import EventIcon from "../Events/EventIcon";

const eventTypeColors = {
  Leak: "#ec2027",
  Flow: "#166aec",
  Offline: "#767e8a",
  Check: "#faa61a",
};

const renderTitle = (eventTypes) => {
  return (
    <div className="flex justify-center items-center">
      {eventTypes?.map((eventType) => (
        <Badge
          showZero={false}
          size="default"
          key={eventType}
          className="pr-1"
          color={eventTypeColors[eventType] || "gray"}
        />
      ))}
    </div>
  );
};

const MonitoringTableColumns = (eventTypes) => [
  {
    title: renderTitle(eventTypes),
    key: "device_icon",
    render: (eventData) => {
      const className = `mt-2 event-name-${eventData?.EventType}`;
      return (
        eventData?.DeviceTypePublicName && (
          <EventIcon
            key={eventData?.Id}
            iconName={eventData?.DeviceTypePublicName}
            className={`${className}`}
            defaultIcon={
              <InfoCircleOutlined
                className={`${className}`}
                style={{ fontSize: 18 }}
              />
            }
          />
        )
      );
    },
    align: "center",
    width: 55,
  },
  {
    title: t("monitoring.property"),
    key: "property",
    render: (eventData) => eventData?.PropertyName,
  },
  {
    title: t("monitoring.floor"),
    key: "floor",
    render: (eventData) => eventData?.FloorName,
    align: "center",
  },
  {
    title: t("monitoring.device_name"),
    key: "device_name",
    render: (eventData) => eventData?.DeviceName,
  },
  {
    title: t("monitoring.device_location"),
    key: "device_location",
    render: (eventData) => eventData?.DeviceLocation,
  },
  // {
  //   title: "Zone Connection",
  //   key: "zone_connection",
  //   render: (eventData) => eventData?.ZoneName,
  // },
  {
    title: t("monitoring.event_name"),
    key: "event_name",
    render: (eventData) => {
      return (
        <div className="inline-flex items-center">
          <span className={`event-name-${eventData?.EventName} mr-1`}>
            {t(`event_name.${eventData?.EventName}`)}
          </span>
          {eventData?.Checks.length > 0 && (
            <Popover
              showArrow={false}
              color="#050529"
              overlayInnerStyle={{
                borderColor: eventTypeColors[eventData?.EventType] || "gray",
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                fontWeight: 400,
                maxWidth: 192,
                width: 192,
              }}
              content={
                <div className="flex flex-col">
                  <ul className="list list-inside list-disc">
                    {eventData?.Checks?.map((item) => {
                      return <li key={item}>{item}</li>;
                    })}
                  </ul>
                </div>
              }
              destroyTooltipOnHide
            >
              <div className="">
                <InfoCircleOutlined
                  className="ml-1 cursor-pointer"
                  style={{
                    color: eventTypeColors[eventData?.EventType] || "gray",
                  }}
                />
              </div>
            </Popover>
          )}
        </div>
      );
    },
  },
  {
    title: t("monitoring.event_type"),
    key: "event_type",
    render: (eventData) => {
      return (
        <span className={`event-name-${eventData?.EventType}`}>
          {t(`event_type.${eventData?.EventType}`)}
        </span>
      );
    },
  },
];
export default MonitoringTableColumns;
