import { Form, Input, Typography } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

const { Text } = Typography;

function FormUserDetails({ operation }) {
  return (
    <div className="user-details">
      <div className="flex justify-center">
        <Text className="uppercase tracking-widest">
          {t("form_user.user_details")}
        </Text>
      </div>
      <div className="border border-solid rounded p-1 mt-2 border-triple-blue">
        <Form.Item className="mb-0">
          <Form.Item
            className="mb-0 inline-block w-1/2"
            label={t("form_user.full_name")}
            colon={false}
            name="fullName"
            rules={[
              { required: true, message: t("form_user.full_name_required") },
            ]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
          <Form.Item
            className="my-0 mx-2 inline-block w-full"
            label={t("form_user.mobile")}
            colon={false}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: t("form_user.mobile_required"),
              },
            ]}
            style={{
              width: "calc(50% - 16px)",
            }}
          >
            <Input size="small" maxLength={15} />
          </Form.Item>
        </Form.Item>
      </div>

      <div className="border border-solid rounded p-1 mt-2 border-triple-blue">
        <Form.Item
          className="m-0"
          label={t("form_user.email")}
          colon={false}
          name="email"
          rules={[
            { required: true, message: t("form_user.email_required") },
            { type: "email", message: t("form_user.invalid_email") },
          ]}
          style={{
            width: "calc(100% - 8px)",
          }}
        >
          <Input
            size="small"
            maxLength={100}
            disabled={!!(operation && operation === "edit")}
          />
        </Form.Item>
      </div>
    </div>
  );
}
FormUserDetails.propTypes = {
  operation: PropTypes.string,
};

FormUserDetails.defaultProps = {
  operation: "",
};
export default FormUserDetails;
