import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Space, Tooltip } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

function FormUserZonesAdd({
  formFieldName,
  userForm,
  onClickAddZone,
  addZoneDisabled,
  handlePropertyChange,
}) {
  return (
    <Form.List name={formFieldName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => {
            return (
              <Space key={key} align="baseline" size={0}>
                <div className="border-triple-blue border border-solid rounded-md flex px-1 mb-1">
                  <Form.Item
                    className="mb-0"
                    name={[name, "propertyName"]}
                    style={{ maxWidth: "100%" }}
                  >
                    <Input
                      placeholder={t("form_user.property")}
                      className="!bg-triple-blue rounded-r-none !cursor-pointer"
                      size="small"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-0"
                    name={[name, "userFullname"]}
                    style={{ maxWidth: 125 }}
                  >
                    <Input
                      placeholder={t("form_user.full_name")}
                      size="small"
                      className="!cursor-pointer"
                      disabled
                      suffix={
                        <Tooltip
                          title={
                            userForm.getFieldValue("addUserZones")[name]
                              ?.userFullname
                          }
                          color="#5a5a5a"
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-0"
                    name={[name, "phoneNumber"]}
                    style={{ maxWidth: 100 }}
                  >
                    <Input
                      placeholder={t("form_user.phone_number")}
                      size="small"
                      className="!cursor-pointer"
                      disabled
                      suffix={
                        <Tooltip
                          title={
                            userForm.getFieldValue("addUserZones")[name]
                              ?.phoneNumber
                          }
                          color="#5a5a5a"
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-0 ml-1 "
                    name={[name, "zone"]}
                    label={t("zone.zone")}
                    colon={false}
                    style={{ maxWidth: "100%" }}
                  >
                    <Input
                      placeholder={t("form_user.zone")}
                      className="!bg-triple-blue rounded-l-none !cursor-pointer"
                      size="small"
                      disabled
                    />
                  </Form.Item>
                </div>
                <DeleteOutlined
                  className="mt-2 ml-2"
                  style={{
                    fontSize: "18px",
                    color: "#01a2d1",
                  }}
                  onClick={() => {
                    remove(name);
                    handlePropertyChange(null);
                  }}
                />
              </Space>
            );
          })}
          <Form.Item className="flex justify-end mr-6">
            <Button
              disabled={addZoneDisabled}
              type="primary"
              shape="circle"
              size="small"
              onClick={() => onClickAddZone(add, fields.length)}
              icon={<PlusOutlined />}
            />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

FormUserZonesAdd.propTypes = {
  formFieldName: PropTypes.string,
  userForm: PropTypes.object,
  onClickAddZone: PropTypes.func,
  addZoneDisabled: PropTypes.bool,
  handlePropertyChange: PropTypes.func,
};
FormUserZonesAdd.defaultProps = {
  formFieldName: "addUserZones",
  userForm: {},
  onClickAddZone: () => {},
  addZoneDisabled: true,
  handlePropertyChange: () => {},
};

export default FormUserZonesAdd;
