import { useEffect, useRef, useState } from "react";

import { Button, Form } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import {
  useCreateHomeAppUser,
  useHomeAppUserZoneFilters,
} from "../../../../api/hooks/useMobileAppUsers";
import FormUserZones from "./helpers/FormUserZones";
import { FormUserDetails, FormZoneSelection } from "./helpers/module";
import { useHandleFieldsChange, useMobileAppUserCreate } from "./hooks/module";
import "./scss/MobileAppUserModal.scss";

function MobileAppUserCreate({
  setIsFormDirty,
  setFormReference,
  setIsCreateUserModalOpen,
  refetchMobileAppUsers,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [addZoneDisabled, setAddZoneDisabled] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [zones, setZones] = useState([]);
  const [createUserForm] = Form.useForm();
  const initialFormValues = useRef({});
  initialFormValues.current = {
    fullName: "",
    phoneNumber: "",
    email: "",
    addUserZones: [],
  };

  const { data: homeAppUserZoneFilters } = useHomeAppUserZoneFilters();

  const createMobileAppUserMutationFn = useCreateHomeAppUser();

  useEffect(() => {
    setFormReference(createUserForm);
  }, [createUserForm, setFormReference]);

  const HandleFieldsChange = (changedFields) => {
    useHandleFieldsChange(
      changedFields,
      createUserForm,
      initialFormValues,
      setDisableSaveChanges,
      setIsFormDirty,
      setAddZoneDisabled,
    );
  };

  const handleOnValuesChange = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  const HandleSubmit = async (formValues) => {
    setIsLoading(true);
    await useMobileAppUserCreate(
      formValues,
      createUserForm,
      setIsLoading,
      setIsFormDirty,
      setIsCreateUserModalOpen,
      refetchMobileAppUsers,
      createMobileAppUserMutationFn,
    );
    setIsLoading(false);
  };

  const handlePropertyChange = (value) => {
    setSelectedProperty(value);
    const selectedProp = homeAppUserZoneFilters?.Value?.Properties.find(
      (prop) => prop.Id === value,
    );
    const alreadySelected = createUserForm.getFieldValue("addUserZones");
    if (selectedProp && alreadySelected) {
      const ids = alreadySelected.map((x) => x.zoneId);
      const zonesToSet = selectedProp.Zones.filter(
        (zone) => !ids.includes(zone.Id),
      );
      setZones(zonesToSet);
    } else {
      setZones(selectedProp ? selectedProp.Zones : []);
    }

    if (!selectedProp) {
      setZones([]);
      createUserForm.setFieldValue("property", null);
      createUserForm.setFieldValue("zone", null);
    }
  };

  return (
    <div className="content-wrapper overflow-y-auto create-mobile-app-user-form mr-4">
      <Form
        form={createUserForm}
        name="create-user-form"
        layout="horizontal"
        className="w-full"
        onFinish={HandleSubmit}
        onFieldsChange={HandleFieldsChange}
        onValuesChange={handleOnValuesChange}
        initialValues={initialFormValues.current}
      >
        <div className="user-title">
          <span className="text-center mr-1 tracking-wider">
            {t("mobile_users.new_mobile_user")}
          </span>
        </div>
        <FormUserDetails />
        <FormZoneSelection
          homeAppUserZoneFilters={homeAppUserZoneFilters}
          userForm={createUserForm}
          handlePropertyChange={handlePropertyChange}
          selectedProperty={selectedProperty}
          zones={zones}
        />
        <FormUserZones
          userForm={createUserForm}
          homeAppUserZoneFilters={homeAppUserZoneFilters}
          setAddZoneDisabled={setAddZoneDisabled}
          addZoneDisabled={addZoneDisabled}
          setZones={setZones}
          handlePropertyChange={handlePropertyChange}
          selectedProperty={selectedProperty}
        />
        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-create-user-save-btn"
          disabled={disableSaveChanges}
          loading={isLoading}
        >
          {t("mobile_users.save")}
        </Button>
      </Form>
    </div>
  );
}

MobileAppUserCreate.propTypes = {
  setIsFormDirty: PropTypes.func,
  setFormReference: PropTypes.func,
  setIsCreateUserModalOpen: PropTypes.func,
  refetchMobileAppUsers: PropTypes.func,
};
MobileAppUserCreate.defaultProps = {
  setIsFormDirty: () => {},
  setFormReference: () => {},
  setIsCreateUserModalOpen: () => {},
  refetchMobileAppUsers: () => {},
};

export default MobileAppUserCreate;
