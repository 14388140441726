import { Form, Select, Typography } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

const { Text } = Typography;
function FormZoneSelection({
  homeAppUserZoneFilters,
  handlePropertyChange,
  selectedProperty,
  zones,
}) {
  const propertyOptions = homeAppUserZoneFilters?.Value?.Properties?.map(
    (property) => {
      return {
        value: property?.Id,
        label: property?.Name,
      };
    },
  );

  const zoneOptions = zones?.map((zone) => {
    return {
      value: zone?.Id,
      label: zone?.ZoneName,
    };
  });

  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div className="zone-selection">
      <div className="flex justify-center mt-4">
        <Text className="uppercase tracking-widest">
          {t("zone.zone_selection")}
        </Text>
      </div>
      <div className="border border-solid rounded p-1 mt-2 border-triple-blue">
        <Form.Item className="mb-0">
          <Form.Item
            className="mb-0 inline-block w-1/2"
            label={t("zone.property")}
            colon={false}
            name="property"
          >
            <Select
              className="w-full zone-selection-property"
              size="small"
              onChange={handlePropertyChange}
              placeholder={t("zone.select_property")}
              showSearch
              allowClear
              filterOption={filterOption}
              options={propertyOptions}
            />
          </Form.Item>
          <Form.Item
            className="my-0 mx-2 inline-block w-full"
            label={t("zone.zone")}
            colon={false}
            name="zone"
            style={{
              width: "calc(50% - 16px)",
            }}
          >
            <Select
              className="w-full zone-selection-property"
              size="small"
              disabled={!selectedProperty}
              placeholder={t("zone.select_zone")}
              showSearch
              allowClear
              filterOption={filterOption}
              options={zoneOptions}
            />
          </Form.Item>
        </Form.Item>
      </div>
    </div>
  );
}
FormZoneSelection.propTypes = {
  homeAppUserZoneFilters: PropTypes.object,
  handlePropertyChange: PropTypes.func,
  selectedProperty: PropTypes.number,
  zones: PropTypes.array,
};
FormZoneSelection.defaultProps = {
  homeAppUserZoneFilters: {},
  handlePropertyChange: () => {},
  selectedProperty: 0,
  zones: [],
};
export default FormZoneSelection;
